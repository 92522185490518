import { classNames } from '@/core/utils'
import { Fragment, ReactNode } from 'react'

const Grid = ({
  headers,
  rows,
}: {
  headers: string[]
  rows: ReactNode[][]
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-6 gap-0 full-w">
      {headers.map((header, index) => (
        <div
          key={index}
          className="bg-cool-gray-900 opacity-60 p-2 col-span-1 md:col-span-1 hidden md:block"
        >
          {header}
        </div>
      ))}

      {rows.map((row, rowIndex) => (
        <Fragment key={rowIndex}>
          {row.map((content, colIndex: number) => (
            <div
              key={colIndex}
              className={`flex flex-row md:flex-col col-span-1 ${
                colIndex === 0 ? 'mt-0 md:mt-0' : ''
              }`}
            >
              <div
                className={classNames(
                  'w-1/4 md:p-2 text-left',
                  colIndex !== headers?.length - 1 && headers[colIndex],
                  headers[colIndex] ? 'md:hidden' : 'hidden'
                )}
              >
                {headers[colIndex]}
              </div>
              <div
                className={classNames(
                  'h-full md:w-full md:p-2 text-right md:text-left whitespace-nowrap flex justify-end md:justify-start',
                  colIndex !== headers?.length - 1 ? 'w-3/4' : 'w-full',
                  content ? '' : 'hidden md:flex'
                )}
              >
                {content}
              </div>
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  )
}

export default Grid
