import {
  arbitrumAbi,
  arbitrumStakingAbi,
  arbitrumLiquidityPoolAbi,
} from '@/abi'
import { MaxUint256, Signature } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import { parseUnits } from 'viem'
import {
  useReadContract,
  useSignTypedData,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi'
import { toast } from 'react-toastify'
import { useCommon } from '@/core/hooks/useCommon'
import { usePoolStats } from '@/core/hooks/usePoolStats'
import { calculateDeadline } from '@/core/utils/calculateDeadline'

export const usePermitAndDeposit = () => {
  const [isTransactionAborted, setTransactionAborted] = useState(false)
  const [isSigned, setSigned] = useState(false)
  const [hash, setHash] = useState<string | null>(null)
  const {
    parseError,
    refetchBalance,
    data,
    contractAddress,
    stakingContractAddress,
    balance,
    isLoading,
    symbol,
    poolId,
    usersPositionData,
  } = useCommon()

  const { refetch: refetchStats } = usePoolStats()

  const contractAbi =
    Number(poolId) === 0 ? arbitrumAbi : arbitrumLiquidityPoolAbi

  const { data: noncesData } = useReadContract({
    chainId: data?.chain?.id,
    address: contractAddress,
    abi: contractAbi,
    functionName: 'nonces',
    args: [data?.account.address as `0x${string}`],
  })

  const { data: allowanceData } = useReadContract({
    abi: contractAbi,
    address: contractAddress,
    args: [
      data?.account.address as `0x${string}`,
      stakingContractAddress || '0x0',
    ],
    functionName: 'allowance',
  })

  const { data: eip712DomainData } = useReadContract({
    abi: contractAbi,
    address: contractAddress,
    functionName: 'eip712Domain',
  })

  const { writeContract } = useWriteContract()
  const { signTypedData } = useSignTypedData()

  const { isLoading: isTransactionLoading } = useWaitForTransactionReceipt({
    hash: (hash as `0x${string}`) ?? undefined,
    pollingInterval: 1_000,
    query: {
      enabled: !!hash,
    },
  })

  useEffect(() => {
    if (!isTransactionLoading && hash) {
      refetchBalance()
      refetchStats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransactionLoading, hash])

  const permitAndDeposit = useCallback(
    ({
      value,
      isDepositingMax,
    }: {
      value: string
      isDepositingMax: boolean
    }) => {
      const deadline = calculateDeadline()
      signTypedData(
        {
          domain: {
            name: eip712DomainData?.[1] || 'Uniswap V2',
            version: eip712DomainData?.[2] || '1',
            chainId: Number(eip712DomainData?.[3] || 42161n),
            verifyingContract:
              eip712DomainData?.[4] ||
              (usersPositionData?.[1]
                .result?.[1] as unknown as '0x${string}') ||
              '0x0',
          },
          types: {
            Permit: [
              { name: 'owner', type: 'address' },
              { name: 'spender', type: 'address' },
              { name: 'value', type: 'uint256' },
              { name: 'nonce', type: 'uint256' },
              { name: 'deadline', type: 'uint256' },
            ],
          },
          primaryType: 'Permit',
          message: {
            owner: data?.account.address as `0x${string}`,
            spender: stakingContractAddress || '0x0',
            value: MaxUint256,
            nonce: noncesData || 0n,
            deadline,
          },
        },
        {
          onSuccess: (signature: string) => {
            const { v, r, s } = Signature.from(signature)
            const amount = isDepositingMax
              ? balance.bigint
              : parseUnits(value, 18)
            setSigned(true)

            writeContract(
              {
                chainId: data?.chain?.id,
                address: stakingContractAddress || '0x0',
                functionName: 'permitDeposit',
                abi: arbitrumStakingAbi,
                args: [
                  BigInt(poolId as string),
                  amount,
                  MaxUint256,
                  deadline,
                  v,
                  r as `0x${string}`,
                  s as `0x${string}`,
                ],
              },
              {
                onSuccess: (hash) => {
                  setHash(hash)
                  toast.success('Stake submitted successfully')
                },
                onError: (error) => {
                  setTransactionAborted(true)
                  parseError(error).then((parsedError) =>
                    toast.error(parsedError || 'An error occurred')
                  )
                },
              }
            )
          },
          onError: (error) => {
            setTransactionAborted(true)
            parseError(error).then((parsedError) =>
              toast.error(parsedError || 'An error occurred')
            )
          },
        }
      )
    },
    [
      data,
      balance,
      parseError,
      noncesData,
      eip712DomainData,
      stakingContractAddress,
      writeContract,
      signTypedData,
      poolId,
      usersPositionData,
    ]
  )

  const deposit = useCallback(
    ({
      value,
      isDepositingMax,
    }: {
      value: string
      isDepositingMax: boolean
    }) => {
      writeContract(
        {
          chainId: data?.chain?.id,
          address: stakingContractAddress || '0x0',
          functionName: 'deposit',
          abi: arbitrumStakingAbi,
          args: [
            BigInt(poolId as string),
            isDepositingMax ? balance.bigint : parseUnits(value, 18),
          ],
        },
        {
          onSuccess: (hash) => {
            setHash(hash)
            toast.success('Stake submitted successfully')
          },
          onError: (error) => {
            setTransactionAborted(true)
            parseError(error).then((parsedError) =>
              toast.error(parsedError || 'An error occurred')
            )
          },
        }
      )
    },
    [data, parseError, stakingContractAddress, writeContract, balance, poolId]
  )

  const onDeposit = useCallback(
    ({
      value,
      isDepositingMax,
    }: {
      value: string
      isDepositingMax: boolean
    }) => {
      const parsedValue = parseUnits(String(value), 18)
      if (parsedValue <= (allowanceData || 0n)) {
        deposit({ value, isDepositingMax })
      } else {
        permitAndDeposit({ value, isDepositingMax })
      }
    },
    [deposit, allowanceData, permitAndDeposit]
  )

  return {
    isTransactionLoading,
    isTransactionAborted,
    setTransactionAborted,
    onDeposit,
    isSigned,
    setSigned,
    balance,
    isLoading,
    symbol,
  }
}
