import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './assets/index.css'
import '@rainbow-me/rainbowkit/styles.css'
import './polyfills'
import { SafeAreaProvider } from '@/core/components/SafeAreaProvider'
import { WagmiProvider } from 'wagmi'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'

import { queryClient, wagmiProviderConfig, persister } from './config'
import { Toast } from './core/components/Toast.tsx'

const warningStyle =
  'color: red; font-size: 18px; font-weight: bold; background-color: #ffeeee; padding: 10px; border-radius: 5px;'
console.log('%cGM GM, This is Crypto Security Alert!', warningStyle)
console.log(
  '%cPlease, do not enter any commands in console if somebody asks you to do so. Be cautious of links or addresses asking for your crypto assets, especially if they come from unknown or unverified sources. Always double-check URLs, use trusted wallets/exchanges, and never share your private keys or sensitive information. Phishing attacks can lead to loss of funds. Stay vigilant and prioritize security measures to protect your crypto assets.\n\nRepubliK Team',
  'font-size: 16px;'
)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <WagmiProvider config={wagmiProviderConfig}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <SafeAreaProvider>
          <App />
          <Toast />
        </SafeAreaProvider>
      </PersistQueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
)
