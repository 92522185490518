import { FC, ReactNode } from 'react'

export const SafeAreaProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="app-container">
      <div className="fixed w-full bg-cool-gray-900 h-2/5 -z-[1] top-0"></div>
      {children}
      <div className="fixed w-full bg-cool-gray-950 h-2/5 -z-[1] bottom-0"></div>
    </div>
  )
}
