export const LaunchWebAppLink = () => {
  return (
    <a
      className="block text-center max-w-40 bg-primary text-xs font-bold text-black hover:text-white rounded-full border border-transparent px-4 py-2  transition-all duration-200 ease-in-out focus:outline-none hover:bg-purple"
      href="https://app.republik.gg"
      target="_blank"
      rel="noreferrer"
    >
      Launch Web App
    </a>
  )
}
