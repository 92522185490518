import { usePoolStats } from '@/core/hooks/usePoolStats'
import { classNames } from '@/core/utils'
import { Skeleton } from '@/core/components/Skeleton'

import arbitrumLogo from '@/assets/images/arbitrum-logo.png'

export const Stats = () => {
  const { tvl, apy, isLoading, stakingContractUrl } = usePoolStats()

  return (
    <div
      className={classNames('flex flex-col', isLoading ? 'animate-pulse' : '')}
    >
      <div className="flex justify-between">
        <h1 className="text-xl font-semibold h-6 flex items-center grow">
          {isLoading ? (
            <Skeleton height="h-3" width="w-40" />
          ) : (
            <>Staking statistics</>
          )}
        </h1>
        {isLoading ? (
          <div className="h-6 flex items-center">
            <Skeleton height="h-3" width="w-20" />
          </div>
        ) : (
          stakingContractUrl && (
            <a
              className="text-arbitrum flex"
              target="_blank"
              href={stakingContractUrl}
              rel="noreferrer"
            >
              <img
                src={arbitrumLogo}
                alt="arbitrum"
                className="w-6 h-6 mr-1 rounded-full"
              />
              View on Arbiscan
            </a>
          )
        )}
      </div>
      <div className="py-6">
        <div className="flex justify-between h-6 items-center">
          <div>
            {isLoading ? (
              <Skeleton height="h-2" width="w-24" />
            ) : (
              <>Estimated APY</>
            )}
          </div>
          <div>
            {isLoading ? <Skeleton height="h-2" width="w-10" /> : <>{apy}*</>}
          </div>
        </div>
        <div className="flex justify-between h-6 items-center">
          <div>
            {isLoading ? (
              <Skeleton height="h-2" width="w-20" />
            ) : (
              <>Current TVL</>
            )}
          </div>
          <div>
            {isLoading ? (
              <Skeleton height="h-2" width="w-20" />
            ) : (
              <>{tvl} RPK</>
            )}
          </div>
        </div>
      </div>
      <div className="text-xs text-cool-gray-200 h-4 flex items-center">
        {isLoading ? (
          <Skeleton height="h-2" width="w-3/4" />
        ) : (
          <>*Variable APY based on current TVL and reward pool size</>
        )}
      </div>
    </div>
  )
}
