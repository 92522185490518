import { FC } from 'react'

import stakeImg from '@/assets/images/stake.svg'
import { classNames } from '@/core/utils'

interface ButtonProps {
  onClick?: () => void
  isDisabled?: boolean
  children: React.ReactNode
  type?: 'button' | 'submit'
  className?: string
}

export const Button: FC<ButtonProps> = ({
  onClick,
  isDisabled,
  children,
  type = 'submit',
  className = '',
}) => {
  return (
    <button
      type={type}
      disabled={isDisabled}
      className={classNames(
        `flex items-center bg-primary text-xs font-bold text-black rounded-full border border-transparent px-6 py-2 hover:bg-primary/90 transition-all duration-200 ease-in-out focus:outline-none ${className}`,
        isDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-90'
      )}
      onClick={onClick}
    >
      <img fetchpriority="low" src={stakeImg} className="-ml-1 h-6" alt="" />
      {children}
    </button>
  )
}
