import { FC } from 'react'
import { Dialog } from '@headlessui/react'
import address from '@/assets/images/address-bar.png'

interface WarningDialogProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onAck: () => void
}

export const WarningDialog: FC<WarningDialogProps> = ({
  isOpen,
  setIsOpen,
  onAck,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto max-w-lg rounded-2xl p-4 bg-white text-cool-gray-850">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            GM GM
          </Dialog.Title>
          <div className="flex flex-col text-left">
            <p>
              Before proceeding, please ensure you are on the official staking
              page at:
              <img src={address} alt="" />
              Scammers often replicate websites to steal funds.
            </p>
            <p>
              Always double-check the URL to verify authenticity. When in doubt,
              use the links provided on the official republik.gg website or
              visit our official Telegram and X accounts.
            </p>
            <br />
            <p>Protect your assets. Stay vigilant. 🚀</p>
          </div>
          <div className="flex justify-center items-center mb-4 mt-8">
            <button
              className="flex justify-center items-center bg-cool-gray-950 text-xs font-bold text-white rounded-full border border-transparent px-6 py-2 hover:bg-cool-gray-/90 transition-all duration-200 ease-in-out focus:outline-none"
              onClick={() => onAck()}
            >
              Acknowledge
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
