export const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <div className="w-full h-10 text-xs text-cool-gray-200 bg-cool-gray-950 border-t border-cool-gray-800 flex flex-grow items-center justify-center">
      <div className="pl-4 grow">
        © {year} Rad RepubliK Pte Ltd. All Rights Reserved.
      </div>
      <div className="pr-4 grow flex justify-end">
        <span className="rounded bg-cool-gray-900 text-cool-gray-200 py-1 px-2">
          v{window?.VERSION}
        </span>
      </div>
    </div>
  )
}
