import { FC } from 'react'

import { ConnectionBanner } from '@/core/components/ConnectionBanner'
import { TopNavBar } from '@/core/components/TopNavBar'
import { Footer } from '@/core/components/Footer'
import { UpdateDetector } from '@/core/components/UpdateDetector'

interface PoolsLayoutProps {
  children: React.ReactNode
}

export const PoolsLayout: FC<PoolsLayoutProps> = ({ children }) => {
  return (
    <div className="h-full grid grid-rows-[auto_1fr_auto] w-full bg-cool-gray-900">
      <header>
        <TopNavBar />
        <ConnectionBanner />
        <UpdateDetector />
      </header>
      <div className="flex flex-col h-full items-center w-full">
        <div className="max-w-lg md:max-w-4xl mx-4 flex flex-col">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  )
}
