import { FC } from 'react'
import useConnectionStatus from '../hooks/useConnectionStatus'

export const ConnectionBanner: FC = () => {
  const isOnline = useConnectionStatus()

  if (isOnline) {
    return null
  }

  return (
    <div
      className="bg-cool-gray-950 border-t border-b border-cool-gray-800 text-danger py-3 w-full flex flex-col items-center"
      role="alert"
    >
      <div className="max-w-lg px-4 text-sm sm:min-w-[512px]">
        <p className="font-bold text-center">
          Oops! It seems like your device is currently offline.
        </p>
      </div>
    </div>
  )
}
