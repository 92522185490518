export const calculateApy = ({
  rewardRate,
  poolWeight,
  totalWeight,
  poolDeposited,
}: {
  rewardRate: bigint
  poolWeight: bigint
  totalWeight: bigint
  poolDeposited: bigint
}) => {
  if (!rewardRate || !poolDeposited || !totalWeight) return 0

  const apy =
    (BigInt(rewardRate) * BigInt(poolWeight) * 3600n * 24n * 365n * 100n) /
    BigInt(poolDeposited) /
    BigInt(totalWeight)

  return Number(apy)
}
