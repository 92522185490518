import { FC } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'

export const UpdateDetector: FC = () => {
  const {
    needRefresh: [isRefreshNeeded],
    updateServiceWorker,
  } = useRegisterSW()

  if (!isRefreshNeeded) {
    return null
  }

  return (
    <div
      onClick={() => updateServiceWorker(true)}
      className="cursor-pointer w-full h-10 text-xs text-white bg-purple border-y border-cool-gray-700 flex flex-grow items-center justify-center"
    >
      <div className="px-4 grow font-bold text-center">
        New version available! Click to refresh.
      </div>
    </div>
  )
}
