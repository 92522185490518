import faqData from './faq.json'
import { useState, FC, ReactNode } from 'react'
import Markdown, { Components } from 'react-markdown'
interface FAQProps {
  type?: 'faq' | 'liquidityFaq'
}

export const FAQ: FC<FAQProps> = ({ type = 'faq' }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0)

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index)
  }
  interface RenderersProps {
    node: unknown
    href?: string
    children?: ReactNode
  }

  const renderers = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    a: ({ node, ...props }: RenderersProps) => {
      return (
        <a target="__blank" className="relative underline" href={props?.href}>
          {props?.children}
        </a>
      )
    },
  } as Partial<Components>

  return (
    <div className="pb-4 pt-6">
      <h2 id="faq" className="text-xl font-semibold">
        FAQ
      </h2>
      <div>
        {faqData[type].map((item, index) => (
          <div key={index}>
            <div
              className={`flex justify-between py-4 cursor-pointer ${index !== faqData[type].length - 1 || index === activeIndex ? 'border-b border-cool-gray-800' : ''}`}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{item.question}</h3>
              <span className="text-primary">
                {activeIndex === index ? '-' : '+'}
              </span>
            </div>
            {activeIndex === index && (
              <div className="py-4 text-sm text-cool-gray-200">
                <Markdown components={renderers}>{item.answer}</Markdown>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
