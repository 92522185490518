import { useLocation, useNavigate } from 'react-router-dom'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
interface ITab {
  name: string
  href: string
}

const tabs: ITab[] = [
  { name: 'Stake', href: '/stake' },
  { name: 'Claim', href: '/claim' },
  { name: 'Unstake', href: '/unstake' },
]

export const NavigationTabs = () => {
  const ref = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const pathPrefix =
    '/' +
    location.pathname.split('/')[1] +
    '/' +
    location.pathname.split('/')[2]

  const pathIndex = useMemo(
    () =>
      tabs.findIndex((item) => pathPrefix + item.href === location.pathname),
    [location.pathname, pathPrefix]
  )

  const [index, setIndex] = useState(pathIndex)

  const slideBg = useCallback(
    (index: number, href?: string) => {
      if (href) {
        navigate(href)
      }
      const bgOffset = index * (100 / tabs.length)
      ref.current?.style.setProperty('--bg-offset', `${bgOffset}%`)
    },
    [navigate]
  )

  useEffect(() => {
    setIndex(pathIndex)
    slideBg(pathIndex)
  }, [pathIndex, slideBg])

  return (
    <div className="border-[1px] rounded-full border-cool-gray-600 p-1 max-w-96 mx-auto">
      <div className="container animate-immediateFadeIn" ref={ref}>
        {tabs.map(({ name, href }, tabIndex) => (
          <button
            key={name}
            onClick={() => {
              setIndex(tabIndex)
              slideBg(tabIndex, pathPrefix + href)
            }}
            className={tabIndex === index ? 'active' : ''}
          >
            {name}
          </button>
        ))}
      </div>
    </div>
  )
}
