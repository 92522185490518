import { arbitrum, arbitrumSepolia } from '@wagmi/core/chains'

import {
  binanceWallet,
  bybitWallet,
  coinbaseWallet,
  krakenWallet,
  ledgerWallet,
  metaMaskWallet,
  okxWallet,
  trustWallet,
  uniswapWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { QueryClient } from '@tanstack/react-query'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { deserialize, serialize } from 'wagmi'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { compress, decompress } from 'lz-string'

export interface IApplicationConfig {
  stage: string
  arbitrumStakingContractAddress: `0x${string}` | null
  sepoliaArbitrumStakingContractAddress: `0x${string}`
  projectId: string
  lpRewardCountdownTimestamp: string
}

type Global = {
  applicationConfig: IApplicationConfig
}

const global: Global =
  typeof window !== 'undefined' ? (window as unknown as Global) : ({} as Global)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const globalConfig = global.applicationConfig ?? ({} as Record<string, any>)

const appConfig: IApplicationConfig = {
  stage: globalConfig?.stage,
  arbitrumStakingContractAddress: '0xD9861c157829C01056Aee96b07610EC036AfC6a2',
  sepoliaArbitrumStakingContractAddress:
    '0x95C3FDaFA71dF77D47eeC86fBE94BfC666720aeA',
  projectId: 'fd92c039e335f74a70bcd4b834cfb3d3',
  lpRewardCountdownTimestamp: '2024-08-05T00:00:00Z',
} as IApplicationConfig

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      networkMode: 'offlineFirst',
    },
  },
})

const persister = createSyncStoragePersister({
  key: 'staking-cache',
  serialize: (data) => compress(serialize(data)),
  storage: window.localStorage,
  deserialize: (data) => deserialize(decompress(data)),
})

const wagmiProviderConfig = getDefaultConfig({
  appName: 'RepubliK Staking',
  projectId: appConfig.projectId,
  chains:
    appConfig.stage === 'development' || import.meta.env.DEV
      ? [arbitrumSepolia, arbitrum]
      : [arbitrum],
  wallets: [
    {
      groupName: 'Wallets',
      wallets: [
        metaMaskWallet,
        okxWallet,
        trustWallet,
        binanceWallet,
        bybitWallet,
        coinbaseWallet,
        uniswapWallet,
        krakenWallet,
        ledgerWallet,
      ],
    },
  ],
})

const rainbowKitTheme = {
  accentColor: '#CCFF00',
  accentColorForeground: 'black',
  backgroundColor: '#131820',
  foregroundColor: 'white',
  textColor: 'white',
  borderColor: '#2d333b',
  cardColor: '#1e242b',
  cardBorderColor: '#2d333b',
  cardTextColor: 'white',
  fonts: {
    body: 'Roboto, Inter, sans-serif',
    heading: 'Roboto, Inter, sans-serif',
  },
}

export {
  queryClient,
  wagmiProviderConfig,
  rainbowKitTheme,
  appConfig,
  persister,
}
