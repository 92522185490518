export const Instructions = () => {
  return (
    <>
      <h4 className="font-bold text-center pb-4">
        How to get liquidity tokens?
      </h4>
      <ul className="list-decimal px-4">
        <li>
          Navigate to{' '}
          <a
            className="underline text-uniswap"
            href="https://app.uniswap.org/add/v2/ETH/0x412F4b922bB9afdc65c7e9B669F4bEC6e554452a?chain=arbitrum"
            target="_blank"
            rel="noreferrer"
          >
            RPK/ETH Liquidity Pool on Uniswap
          </a>
        </li>
        <li>
          Provide equal amounts of liquidity in{' '}
          <a
            className="underline"
            target="_blank"
            rel="noreferrer"
            href="https://app.uniswap.org/explore/tokens/arbitrum/0x412f4b922bb9afdc65c7e9b669f4bec6e554452a"
          >
            {' '}
            RPK (Arbitrum)
          </a>{' '}
          and{' '}
          <a
            className="underline"
            target="_blank"
            rel="noreferrer"
            href="https://app.uniswap.org/explore/tokens/arbitrum/NATIVE"
          >
            Ethereum (Arbitrum)
          </a>
        </li>
        <li>
          Take the liquidity token that you receive from Uniswap (UNI-V2) and
          stake it here for additional yield.
        </li>
      </ul>
    </>
  )
}
