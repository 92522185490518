import { FC } from 'react'
import { Form } from '../components/Form'
import { MainCard } from '@/core/components/MainCard'

export const IndexPage: FC = () => {
  return (
    <MainCard isCurrentRewardShown={false} isCurrentBalanceShown>
      <Form />
    </MainCard>
  )
}
