import { FC } from 'react'
import { classNames } from '../utils'

interface CardProps {
  children: React.ReactNode
  id?: string
  isDashed?: boolean
}

export const Card: FC<CardProps> = ({ children, id, isDashed = false }) => {
  return (
    <div
      id={id}
      className={classNames(
        'rounded-2xl p-4 sm:p-8 bg-cool-gray-850 bg-cover bg-center',
        isDashed
          ? 'border-2 border-dashed border-cool-gray-800 bg-transparent'
          : ''
      )}
    >
      {children}
    </div>
  )
}
