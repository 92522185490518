import { FC } from 'react'
import { PoolsLayout } from '@/layouts/PoolsLayout'
import { PoolsList } from '../components/PoolsList'
import { normalizeSymbol } from '@/core/utils/normalizeSymbol'

export const IndexPage: FC = () => {
  return (
    <PoolsLayout>
      <div className="max-w-lg mx-auto">
        <div className="text-center mt-2 mb-10">
          <h1 className="text-4xl font-bold mb-2 mt-8">
            <span className="text-primary">Staking</span>
          </h1>
          <p className="mb-8 mt-2 space-y-12 px-6">
            Stake RPK and {normalizeSymbol('UNI-V2')} to earn yield. <br />
            You can withdraw your assets at any time.
          </p>
        </div>
      </div>
      <PoolsList />
    </PoolsLayout>
  )
}
