import {
  MutableRefObject,
  useEffect,
  ChangeEvent,
  useRef,
  useState,
} from 'react'
import { CurrencyInput } from '@/core/components/CurrencyInput'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useAccount } from 'wagmi'
import { usePermitAndDeposit } from '../hooks/usePermitAndDeposit'
import { Button } from '@/core/components/Button'
import { TransactionDialog } from '@/core/components/TransactionDialog'
import { useForm, useField } from '@tanstack/react-form'
import type { FieldApi } from '@tanstack/react-form'
import { Skeleton } from '@/core/components/Skeleton'
import useConnectionStatus from '@/core/hooks/useConnectionStatus'
import { ConnectButtonWrapper } from '@/core/components/ConnectButtonWrapper'

import { FC } from 'react'

interface FormProps {
  isProvideLiquidityButtonActive: boolean
}

function FieldInfo({
  field,
  balance,
  symbol,
}: {
  field: FieldApi<{ stake: string }, 'stake', undefined, undefined, string>
  balance: { value: number; formatted: string; bigint: bigint }
  symbol: string
}) {
  return (
    <>
      {field.state.meta.isTouched && field.state.meta.errors.length ? (
        <div className="text-sm text-right pt-2 h-8 text-danger">
          {field.state.meta.errors.join(', ')}
        </div>
      ) : (
        <div className="text-sm text-right pt-2 h-8">
          {balance && (
            <>
              Available Balance: {balance.formatted} {symbol}
            </>
          )}
        </div>
      )}
    </>
  )
}

export const Form: FC<FormProps> = ({ isProvideLiquidityButtonActive }) => {
  const [isDepositingMax, setIsDepositingMax] = useState<boolean>(false)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const { openConnectModal } = useConnectModal()
  const { isConnected } = useAccount()
  const isOnline = useConnectionStatus()

  const {
    isTransactionLoading,
    onDeposit,
    isTransactionAborted,
    setSigned,
    isSigned,
    setTransactionAborted,
    balance,
    isLoading,
    symbol,
  } = usePermitAndDeposit()

  const form = useForm({
    defaultValues: {
      stake: '',
    },
    onSubmit: ({ value }) => {
      if (!isOnline) return
      if (isConnected) {
        if (value.stake) {
          onDeposit({
            value: value.stake,
            isDepositingMax,
          })
          setIsOpenDialog(true)
        }
        return
      }
      openConnectModal?.()
    },
  })

  const field = useField({
    name: 'stake',
    form,
  })

  const ref = useRef<HTMLInputElement>(
    null
  ) as MutableRefObject<HTMLInputElement>

  const handleMax = () => {
    if (!balance.value) return
    ref.current?.focus()
    setIsDepositingMax(true)
    field.setValue(String(balance.value))
  }

  useEffect(() => {
    setIsOpenDialog(false)
    form.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransactionLoading])

  useEffect(() => {
    if (isTransactionAborted) {
      setIsOpenDialog(false)
      setTransactionAborted(false)
      setSigned(false)
    }
  }, [isTransactionAborted, setTransactionAborted, setSigned])

  return (
    <>
      <TransactionDialog
        setIsOpen={setIsOpenDialog}
        isOpen={isOpenDialog}
        isStepTwo={isSigned}
      />
      <div className="mt-0">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            form.handleSubmit()
          }}
        >
          <div>
            <div className="flex justify-between text-base font-medium text-white mb-4">
              <div className="text-xl font-semibold">
                {isLoading ? (
                  <Skeleton height="h-6" width="w-32" />
                ) : (
                  <>{symbol} Amount</>
                )}
              </div>
              <div className="text-right">
                <form.Field
                  name="stake"
                  validators={{
                    onChange: ({ value }) => {
                      if (!value) {
                        return 'Required'
                      } else if (value.length > 18) {
                        return 'Must be 18 characters or less'
                      } else if (
                        Number(value) > Number(balance.value) ||
                        Number(balance.value) === 0
                      ) {
                        return 'Insufficient funds'
                      } else if (Number(value) <= 0) {
                        return 'Amount should be greater than 0'
                      } else {
                        return undefined
                      }
                    },
                  }}
                >
                  {(field) => {
                    return (
                      <>
                        <CurrencyInput
                          className="h-8"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setIsDepositingMax(false)
                            field.handleChange(e.target.value)
                          }}
                          value={field.state.value}
                          onBlur={field.handleBlur}
                          name={field.name}
                          inputRef={ref}
                        />
                        <button type="button" onClick={handleMax}>
                          MAX
                        </button>
                        {isLoading ? (
                          <div className="text-sm pt-3 text-right flex justify-end h-8">
                            <Skeleton height="h-2" width="w-40" />
                          </div>
                        ) : (
                          <FieldInfo
                            field={field}
                            balance={balance}
                            symbol={symbol}
                          />
                        )}
                      </>
                    )
                  }}
                </form.Field>
              </div>
            </div>
            <div className="pb-4 mb-4 mt-8 flex flex-col md:flex-row items-center justify-center">
              {isProvideLiquidityButtonActive && (
                <a
                  href="https://app.uniswap.org/add/v2/ETH/0x412F4b922bB9afdc65c7e9B669F4bEC6e554452a?chain=arbitrum"
                  target="_blank"
                  rel="noreferrer"
                  className="block text-center min-w-[180px] bg-uniswap text-xs font-bold text-white justify-center rounded-full border border-transparent px-6 py-2 mr-0 md:mr-4 mb-4 md:mb-0"
                >
                  <span className="flex h-6 items-center justify-center">
                    Provide Liquidity
                  </span>
                </a>
              )}
              <ConnectButtonWrapper>
                <form.Subscribe
                  selector={(state) => [state.canSubmit, state.isSubmitting]}
                >
                  {([canSubmit]) => (
                    <Button isDisabled={!canSubmit}>Stake {symbol}</Button>
                  )}
                </form.Subscribe>
              </ConnectButtonWrapper>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
