import { NavLink } from 'react-router-dom'
import { usePools } from '../hooks/usePools'
import bg from '@/assets/images/staking-background@2x.png'
import Grid from './Grid'
import { Button } from '@/core/components/Button'
import uniswap from '@/assets/images/uniswap-logo.png'
import arbitrum from '@/assets/images/arbitrum-logo.png'
import republikLogo from '@/assets/images/republik-logo.png'
import ethereumLogo from '@/assets/images/ethereum-logo.png'

export interface PoolItem {
  id: bigint
  apy: string
  tvl: string
  dex?: string
  name: string
  chainName: string
}

export const PoolsList = () => {
  const { pools } = usePools()

  const poolHeader = ['Pool', 'Network', '', 'APY', 'TVL', '']
  const liquidityPoolHeader = ['Pool', 'Network', 'DEX', 'APY', 'TVL', '']

  const dexName = (dex: string) => {
    if (dex === 'Uniswap') {
      return (
        <a
          className="flex"
          href="https://uniswap.org/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={uniswap}
            alt="uniswap"
            className="w-6 h-6 mr-1 rounded-full float-left border-4 border-cool-gray-800"
          />
          {dex}
        </a>
      )
    }
    return dex
  }

  const networkName = (network: string) => {
    if (network === 'Arbitrum One') {
      return (
        <span className="flex w-auto">
          <img
            src={arbitrum}
            alt="arbitrum"
            className="w-6 h-6 mr-1 rounded-full float-left"
          />
          {network}
        </span>
      )
    }
    return network
  }

  const categoryName = (category: string, categoryType: string) => {
    const isLiquidityPairPools = categoryType === 'liquidityPools'

    return (
      <div className="flex">
        <span>{category}</span>
        <span className="relative flex items-center pl-2">
          <img
            src={republikLogo}
            alt="republikLogo"
            className="w-6 h-6 mr-1 rounded-full z-10"
          />
          {isLiquidityPairPools && (
            <img
              src={ethereumLogo}
              alt="ethereumLogo"
              className="w-6 h-6 mr-1 border-[1px] border-cool-gray-900 rounded-full absolute m-4"
            />
          )}
        </span>
      </div>
    )
  }

  return (
    <div className="flex w-full flex-col">
      {pools.map((pool) => (
        <div
          key={pool.categoryType}
          className="mb-6 relative rounded-2xl bg-transparent"
        >
          <img
            alt=""
            src={bg}
            fetchpriority="high"
            className="rounded-2xl w-full h-full absolute object-cover"
          />
          <div className="p-4 sm:p-8 relative">
            <div className="text-xl font-semibold pb-4 text-primary">
              {categoryName(pool.categoryName, pool.categoryType)}
            </div>
            <Grid
              headers={
                pool.categoryType === 'liquidityPools'
                  ? liquidityPoolHeader
                  : poolHeader
              }
              rows={pool.items.map(
                ({ id, apy, tvl, dex, name, chainName }: PoolItem) => [
                  <NavLink key={id} to={`/pools/${id}/stake`}>
                    {name}
                  </NavLink>,
                  networkName(chainName),
                  dexName(dex || ''),
                  apy,
                  `${tvl} RPK`,
                  <NavLink
                    key={id}
                    to={`/pools/${id}/stake`}
                    className="flex w-full justify-center item-center"
                  >
                    <Button className="md:px-2 md:py-0">Stake</Button>
                  </NavLink>,
                ]
              )}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
