import { FC } from 'react'
import { Skeleton } from './Skeleton'
import { classNames } from '../utils'
import { useCommon } from '../hooks/useCommon'
import bg from '@/assets/images/staking-background@2x.png'

interface MainCardProps {
  children: React.ReactNode
  isCurrentRewardShown?: boolean
  isCurrentBalanceShown?: boolean
}

export const MainCard: FC<MainCardProps> = ({
  children,
  isCurrentRewardShown = true,
  isCurrentBalanceShown = false,
}) => {
  const { userRewardData, stakedAmountData, isLoading, balance, symbol } =
    useCommon()

  return (
    <div className="relative rounded-2xl bg-transparent">
      <img
        alt=""
        src={bg}
        fetchpriority="high"
        className="rounded-2xl w-full h-full absolute object-cover"
      />
      <div className="p-4 sm:p-8 relative">
        <div
          className={classNames(
            'divide-y divide-solid divide-cool-gray-300 divide-opacity-20',
            isLoading ? 'animate-pulse' : ''
          )}
        >
          <div>{children}</div>
          <div className="mt-4 pt-4">
            <div className="flex justify-between items-center text-base font-small text-white h-6">
              <div>
                {isLoading ? (
                  <Skeleton height="h-2" width="w-28" />
                ) : (
                  <>Amount Staked</>
                )}
              </div>
              <div>
                {isLoading ? (
                  <Skeleton height="h-2" width="w-20" />
                ) : (
                  <>
                    {stakedAmountData.formatted} {symbol}
                  </>
                )}
              </div>
            </div>
            {isCurrentBalanceShown && (
              <div className="flex justify-between items-center text-base font-small text-white h-6">
                <div>
                  {isLoading ? (
                    <Skeleton height="h-2" width="w-16" />
                  ) : (
                    <>Available Balance</>
                  )}
                </div>
                <div>
                  {isLoading ? (
                    <Skeleton height="h-2" width="w-32" />
                  ) : (
                    <>
                      {balance?.formatted} {symbol}
                    </>
                  )}
                </div>
              </div>
            )}
            {isCurrentRewardShown && (
              <div
                className={`flex justify-between items-center text-base font-small text-white sm:h-6`}
              >
                <div>
                  {isLoading ? (
                    <Skeleton height="h-2" width="w-28" />
                  ) : (
                    <>Current Reward</>
                  )}
                </div>
                <div>
                  {isLoading ? (
                    <Skeleton height="h-2" width="w-28" />
                  ) : (
                    <span className="text-primary">
                      <>{userRewardData.formatted} RPK</>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
