import { ConnectButton } from '@rainbow-me/rainbowkit'
import { NavigationTabs } from './NavigationTabs'
import { useAccount } from 'wagmi'
import { useCommon } from '../hooks/useCommon'
import { NavLink } from 'react-router-dom'

export const HeroHeader = () => {
  const { isConnected } = useAccount()
  const { symbol, poolId } = useCommon()
  return (
    <>
      <div className="mt-6 m-auto flex">
        <div className="flex justify-center items-center">
          <NavLink to={'/'} className="flex justify-center items-center">
            <svg
              className="w-4 h-4 text-primary"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 8 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"
              />
            </svg>
            <span className="hidden md:flex text-primary">Back</span>
          </NavLink>
        </div>
        {isConnected && (
          <div className="flex items-end justify-end h-10 w-full">
            <ConnectButton showBalance />
          </div>
        )}
      </div>
      <div className="text-center mt-2 mb-10">
        <h1 className="text-4xl font-bold mb-2 mt-8">
          Stake <span className="text-primary">{symbol}</span>
        </h1>
        <p className="mb-8 mt-2 space-y-12 px-6">
          {Number(poolId) === 1 ? (
            <>
              Stake {symbol} liquidity to get real-time yield. You can withdraw
              your staked {symbol} at any time.
              <br />
              <br />
              <a className="text-primary underline" href="#instructions">
                How to get {symbol} liquidity tokens?
              </a>
            </>
          ) : (
            <>
              Stake {symbol} to get realtime rewards. You can withdraw your
              staked {symbol} at any time.
            </>
          )}
        </p>
        <NavigationTabs />
      </div>
    </>
  )
}
