import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Navigate,
} from 'react-router-dom'
import { Error, Root } from './core/pages'
import { StakePage } from '@/features/poolDetails/stake'
import { ClaimPage } from '@/features/poolDetails/claim'
import { UnstakePage } from '@/features/poolDetails/unstake'
import { PoolsPage } from '@/features/pools'

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Outlet />} errorElement={<Error />}>
        <Route path="/" element={<PoolsPage />} />
        <Route path="pools/:poolId" element={<Root />}>
          <Route path="stake" element={<StakePage />} />
          <Route path="claim" element={<ClaimPage />} />
          <Route path="unstake" element={<UnstakePage />} />
        </Route>
        <Route
          path="liquidity"
          element={<Navigate replace to="/pools/1/stake" />}
        />
      </Route>
    )
  )
  return <RouterProvider router={router} />
}

export default App
