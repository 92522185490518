import { MainCard } from '@/core/components/MainCard'
import { Form } from '../components/Form'
import { useCommon } from '@/core/hooks/useCommon'

export const IndexPage = () => {
  const { poolId } = useCommon()
  return (
    <MainCard>
      <Form isProvideLiquidityButtonActive={Number(poolId) === 1} />
    </MainCard>
  )
}
