import { Skeleton } from '@/core/components/Skeleton'
import { useClaim } from '../hooks/useClaim'
import { Button } from '@/core/components/Button'
import { useEffect, useState } from 'react'
import { useForm } from '@tanstack/react-form'
import type { FieldApi } from '@tanstack/react-form'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useAccount } from 'wagmi'
import { TransactionDialog } from '@/core/components/TransactionDialog'
import useConnectionStatus from '@/core/hooks/useConnectionStatus'
import { ConnectButtonWrapper } from '@/core/components/ConnectButtonWrapper'

export const Form = () => {
  const isOnline = useConnectionStatus()
  const {
    onClaim,
    currentClaimableReward,
    isTransactionLoading,
    isTransactionAborted,
    setTransactionAborted,
    isLoading,
  } = useClaim()
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const { openConnectModal } = useConnectModal()
  const { isConnected } = useAccount()

  function FieldInfo({
    field,
  }: {
    field: FieldApi<{ reward: string }, 'reward', undefined, undefined, string>
  }) {
    return (
      <div className="text-right flex justify-end h-8">
        {field.state.meta.isTouched && field.state.meta.errors.length ? (
          <div className="text-sm text-right pt-2 h-8 text-danger">
            {field.state.meta.errors.join(', ')}
          </div>
        ) : null}
      </div>
    )
  }

  const form = useForm({
    defaultValues: {
      reward: currentClaimableReward,
    },
    onSubmit: ({ value }) => {
      if (!isOnline) return
      if (isConnected) {
        if (value.reward) {
          onClaim()
          setIsOpenDialog(true)
        }
        return
      }
      openConnectModal?.()
    },
  })

  useEffect(() => {
    setIsOpenDialog(false)
    form.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransactionLoading])

  useEffect(() => {
    if (isTransactionAborted) {
      setIsOpenDialog(false)
      setTransactionAborted(false)
    }
  }, [isTransactionAborted, setTransactionAborted])

  return (
    <>
      <TransactionDialog setIsOpen={setIsOpenDialog} isOpen={isOpenDialog} />
      <div className="mt-0">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            form.handleSubmit()
          }}
        >
          <div>
            <div className="flex justify-between text-base font-medium text-white mb-4">
              <div className="text-xl font-semibold">
                {isLoading ? (
                  <Skeleton height="h-3" width="w-40" />
                ) : (
                  'Claimable Reward'
                )}
              </div>
              <div className="text-right text-xl font-semibold">
                <form.Field
                  name="reward"
                  validators={{
                    onChange: ({ value }) =>
                      value === '0' ? 'Nothing to claim yet' : undefined,
                  }}
                >
                  {(field) => {
                    return (
                      <>
                        <div className="h-8">{currentClaimableReward} RPK</div>
                        <FieldInfo field={field} />
                      </>
                    )
                  }}
                </form.Field>
              </div>
            </div>
            <div className="pb-4 mb-4 mt-8 flex justify-center">
              <ConnectButtonWrapper>
                <form.Subscribe
                  selector={(state) => [state.canSubmit, state.isSubmitting]}
                >
                  {([canSubmit]) => (
                    <Button isDisabled={!canSubmit}>Claim RPK</Button>
                  )}
                </form.Subscribe>
              </ConnectButtonWrapper>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
