import { arbitrumStakingAbi } from '@/abi'
import { useCommon } from '@/core/hooks/useCommon'
import { useCallback, useEffect, useState } from 'react'
import { useWaitForTransactionReceipt, useWriteContract } from 'wagmi'
import { toast } from 'react-toastify'
import { parseUnits } from 'viem'
import { usePoolStats } from '@/core/hooks/usePoolStats'

export const useUnstake = () => {
  const [hash, setHash] = useState<string | null>(null)
  const [isTransactionAborted, setTransactionAborted] = useState(false)
  const {
    parseError,
    data,
    stakingContractAddress,
    refetchBalance,
    balance,
    isLoading,
    stakedAmountData,
    symbol,
    poolId,
  } = useCommon()
  const { writeContract } = useWriteContract()
  const { refetch: refetchStats } = usePoolStats()

  const { isLoading: isTransactionLoading } = useWaitForTransactionReceipt({
    hash: (hash as `0x${string}`) ?? undefined,
    pollingInterval: 1_000,
    query: {
      enabled: !!hash,
    },
  })

  useEffect(() => {
    if (!isTransactionLoading && hash) {
      refetchBalance()
      refetchStats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransactionLoading, hash])

  const onUnstake = useCallback(
    ({
      value,
      isWithdrawingMax,
    }: {
      value: string
      isWithdrawingMax: boolean
    }) => {
      writeContract(
        {
          chainId: data?.chain?.id,
          address: stakingContractAddress || '0x0',
          functionName: 'withdraw',
          abi: arbitrumStakingAbi,
          args: [
            BigInt(poolId as string),
            isWithdrawingMax
              ? stakedAmountData.bigint
              : parseUnits(String(value), 18),
          ],
        },
        {
          onSuccess: (hash) => {
            setHash(hash)
            toast.success('Unstake submitted successfully')
          },
          onError: (error) => {
            setTransactionAborted(true)
            parseError(error).then((parsedError) =>
              toast.error(parsedError || 'An error occurred')
            )
          },
        }
      )
    },
    [
      data,
      stakingContractAddress,
      writeContract,
      parseError,
      stakedAmountData,
      poolId,
    ]
  )

  return {
    isLoading,
    isTransactionAborted,
    isTransactionLoading,
    setTransactionAborted,
    balance,
    onUnstake,
    stakedAmountData,
    symbol,
  }
}
