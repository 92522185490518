import { FC } from 'react'

import { HeroHeader } from '@/core/components/HeroHeader'
import { FAQ } from '@/features/faq'
import { Card } from '@/core/components/Card'
import { Stats } from './components/Stats'
import { ConnectionBanner } from '@/core/components/ConnectionBanner'
import { TopNavBar } from '@/core/components/TopNavBar'
import { Footer } from '@/core/components/Footer'
import { UpdateDetector } from '@/core/components/UpdateDetector'
import { Instructions } from './components/Instructions'
import { useParams } from 'react-router-dom'
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { arbitrumSepolia } from 'viem/chains'
import { rainbowKitTheme } from '@/config'

interface MainLayoutProps {
  children: React.ReactNode
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { poolId } = useParams<{ poolId: string }>()
  return (
    <RainbowKitProvider
      initialChain={arbitrumSepolia}
      theme={darkTheme(rainbowKitTheme)}
    >
      <div className="flex flex-col items-center w-full bg-cool-gray-900">
        <TopNavBar />
        <ConnectionBanner />
        <UpdateDetector />
        <div className="max-w-lg mx-4">
          <HeroHeader />
          {children}
          <div className="my-6" />
          <Card>
            <Stats />
          </Card>
          {Number(poolId) === 1 && (
            <>
              <div className="my-6" />
              <Card isDashed id="instructions">
                <Instructions />
              </Card>
            </>
          )}
          <FAQ type={Number(poolId) === 1 ? 'liquidityFaq' : 'faq'} />
        </div>
        <Footer />
      </div>
    </RainbowKitProvider>
  )
}
