export const fixString = (value: string, fractionDigits = 2): number => {
  const fractions = fractionDigits
  const amount =
    value.split('.')[0] + '.' + value.split('.')?.[1]?.slice(0, fractions)
  return Number.parseFloat(amount)
}

export const formatCurrency = (value: string, fractionDigits = 2) => {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: fractionDigits,
  }).format(fixString(value, fractionDigits))
}
