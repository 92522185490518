import { arbitrumLiquidityPoolAbi, arbitrumStakingAbi } from '@/abi'
import { appConfig } from '@/config'
import useConnectionStatus from '@/core/hooks/useConnectionStatus'
import { calculateApy, formatCurrency } from '@/core/utils'
import { normalizeSymbol } from '@/core/utils/normalizeSymbol'
import { useCallback } from 'react'
import { formatEther } from 'viem'
import { useReadContracts } from 'wagmi'
import { arbitrum } from 'wagmi/chains'

export const usePools = () => {
  const poolIds = [
    {
      id: 0n,
      position: 2,
      name: 'RPK',
      chainId: arbitrum.id,
      chainName: arbitrum.name,
    },
  ]
  const liquidityPoolIds = [
    {
      id: 1n,
      position: 3,
      name: normalizeSymbol('UNI-V2'),
      chainId: arbitrum.id,
      chainName: arbitrum.name,
    },
  ]

  const pools = poolIds.concat(liquidityPoolIds)

  const stakingContractAddress = appConfig.arbitrumStakingContractAddress

  const isOnline = useConnectionStatus()

  const {
    data: contractsData,
    isLoading,
    refetch,
  } = useReadContracts({
    contracts: [
      {
        chainId: arbitrum.id,
        address: stakingContractAddress || '0x0',
        abi: arbitrumStakingAbi,
        functionName: 'rewardRate',
      },
      {
        chainId: arbitrum.id,
        address: stakingContractAddress || '0x0',
        abi: arbitrumStakingAbi,
        functionName: 'totalWeight',
      },
      ...pools.map(({ id, chainId }) => {
        return {
          chainId,
          address: stakingContractAddress || '0x0',
          abi: arbitrumStakingAbi,
          functionName: 'pools',
          args: [id],
        }
      }),
    ],
    query: {
      enabled: isOnline ? !!stakingContractAddress : false,
      refetchInterval: 1000 * 10,
    },
  })

  const arbitrumLPContractAddress = (
    contractsData?.[3]?.result as unknown as bigint[]
  )?.[1]

  const { data: lPContractsData } = useReadContracts({
    contracts: [
      {
        chainId: arbitrum.id,
        address:
          (arbitrumLPContractAddress as unknown as `0x${string}`) || '0x0',
        abi: arbitrumLiquidityPoolAbi,
        functionName: 'getReserves',
      },
      {
        chainId: arbitrum.id,
        address:
          (arbitrumLPContractAddress as unknown as `0x${string}`) || '0x0',
        abi: arbitrumLiquidityPoolAbi,
        functionName: 'totalSupply',
      },
    ],
    query: {
      enabled: isOnline
        ? !!(stakingContractAddress && contractsData?.[3]?.result)
        : false,
      refetchInterval: 1000 * 10,
    },
  })

  const calculateLPTotalValue = useCallback(() => {
    let lpTotalValue: bigint = 0n

    const poolDeposited =
      (contractsData?.[3]?.result as unknown as bigint[])?.[3] || 0n

    const reserver =
      (lPContractsData?.[0]?.result as unknown as bigint[])?.[0] || 0n

    const totalSupply =
      (lPContractsData?.[1]?.result as unknown as bigint) || 0n

    if (totalSupply !== 0n) {
      lpTotalValue =
        (BigInt(reserver) * BigInt(poolDeposited) * 2n) / BigInt(totalSupply)
    }

    return lpTotalValue
  }, [contractsData, lPContractsData])

  const apy = useCallback(
    (position: number) => {
      const rewardRate = (contractsData?.[0]?.result as unknown as bigint) || 0n
      const poolWeight =
        (contractsData?.[position]?.result as unknown as bigint[])?.[2] || 0n
      const totalWeight =
        (contractsData?.[1]?.result as unknown as bigint) || 0n
      const poolDeposited =
        (contractsData?.[position]?.result as unknown as bigint[])?.[3] || 0n

      return `${calculateApy({
        rewardRate,
        poolWeight,
        totalWeight,
        poolDeposited,
      }).toFixed(0)}%`
    },
    [contractsData]
  )

  const tvl = useCallback(
    (position: number) => {
      const value = formatEther(
        (contractsData?.[position]?.result as unknown as bigint[])?.[3] || 0n
      )

      return formatCurrency(value)
    },
    [contractsData]
  )

  const liquidityPoolsTvl = useCallback(() => {
    const value = String(calculateLPTotalValue() / BigInt(10 ** 18))

    return formatCurrency(value)
  }, [calculateLPTotalValue])

  const liquidityPoolApy = useCallback(
    (position: number) => {
      const rewardRate = (contractsData?.[0]?.result as unknown as bigint) || 0n
      const poolWeight =
        (contractsData?.[position]?.result as unknown as bigint[])?.[2] || 0n
      const totalWeight =
        (contractsData?.[1]?.result as unknown as bigint) || 0n

      const poolDeposited = calculateLPTotalValue()

      return `${calculateApy({
        rewardRate,
        poolWeight,
        totalWeight,
        poolDeposited,
      }).toFixed(0)}%`
    },
    [contractsData, calculateLPTotalValue]
  )

  return {
    isLoading,
    refetch,
    pools: [
      {
        categoryName: 'Liquidity Pair Pools',
        categoryType: 'liquidityPools',
        items: liquidityPoolIds.map(
          ({ id, position, name, chainId, chainName }) => ({
            id,
            name,
            apy: liquidityPoolApy(position),
            tvl: liquidityPoolsTvl(),
            dex: 'Uniswap',
            chainId,
            chainName,
          })
        ),
      },
      {
        categoryName: 'RPK Pools',
        categoryType: 'pools',
        items: poolIds.map(({ id, position, name, chainId, chainName }) => ({
          id,
          name,
          apy: apy(position),
          tvl: tvl(position),
          chainId,
          chainName,
        })),
      },
    ],
  }
}
