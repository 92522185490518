import { Skeleton } from '@/core/components/Skeleton'
import { useUnstake } from '../hooks/useUnstake'
import { Button } from '@/core/components/Button'
import {
  ChangeEvent,
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useForm, useField } from '@tanstack/react-form'
import type { FieldApi } from '@tanstack/react-form'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useAccount } from 'wagmi'
import { TransactionDialog } from '@/core/components/TransactionDialog'
import { CurrencyInput } from '@/core/components/CurrencyInput'
import useConnectionStatus from '@/core/hooks/useConnectionStatus'
import { ConnectButtonWrapper } from '@/core/components/ConnectButtonWrapper'

interface FormProps {
  isRemoveLiquidityButtonActive: boolean
}

function FieldInfo({
  field,
}: {
  field: FieldApi<{ reward: string }, 'reward', undefined, undefined, string>
}) {
  return (
    <div className="text-right flex justify-end h-8">
      {field.state.meta.isTouched && field.state.meta.errors.length ? (
        <div className="text-sm text-right pt-2 h-8 text-danger">
          {field.state.meta.errors.join(', ')}
        </div>
      ) : null}
    </div>
  )
}

export const Form: FC<FormProps> = ({ isRemoveLiquidityButtonActive }) => {
  const isOnline = useConnectionStatus()
  const ref = useRef<HTMLInputElement>(
    null
  ) as MutableRefObject<HTMLInputElement>

  const [isWithdrawingMax, setIsWithdrawingMax] = useState<boolean>(false)

  const {
    onUnstake,
    stakedAmountData,
    isTransactionLoading,
    isTransactionAborted,
    setTransactionAborted,
    isLoading,
    symbol,
  } = useUnstake()
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const { openConnectModal } = useConnectModal()
  const { isConnected } = useAccount()

  const form = useForm({
    defaultValues: {
      reward: '',
    },
    onSubmit: ({ value }) => {
      if (!isOnline) return
      if (isConnected) {
        if (value.reward) {
          onUnstake({
            value: value.reward,
            isWithdrawingMax,
          })
          setIsOpenDialog(true)
        }
        return
      }
      openConnectModal?.()
    },
  })

  useEffect(() => {
    setIsOpenDialog(false)
    form.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransactionLoading])

  useEffect(() => {
    if (isTransactionAborted) {
      setIsOpenDialog(false)
      setTransactionAborted(false)
    }
  }, [isTransactionAborted, setTransactionAborted])

  const field = useField({
    name: 'reward',
    form,
  })

  const handleMax = () => {
    if (!stakedAmountData.value) return
    ref.current?.focus()
    setIsWithdrawingMax(true)
    field.setValue(String(stakedAmountData.value))
  }

  return (
    <>
      <TransactionDialog setIsOpen={setIsOpenDialog} isOpen={isOpenDialog} />
      <div className="mt-0">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            form.handleSubmit()
          }}
        >
          <div>
            <div className="flex justify-between text-base font-medium text-white mb-4">
              <div className="text-xl font-semibold">
                {isLoading ? (
                  <Skeleton height="h-6" width="w-32" />
                ) : (
                  <>{symbol} Amount</>
                )}
              </div>
              <div className="text-right">
                <form.Field
                  name="reward"
                  validators={{
                    onChange: ({ value }) => {
                      if (!value) {
                        return 'Required'
                      } else if (
                        Number(value) > Number(stakedAmountData.value)
                      ) {
                        return 'Amount is exceeding the staked amount'
                      } else if (Number(value) <= 0) {
                        return 'Amount should be greater than 0'
                      } else {
                        return undefined
                      }
                    },
                  }}
                >
                  {(field) => {
                    return (
                      <>
                        <CurrencyInput
                          className="h-8"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            field.handleChange(e.target.value)
                            setIsWithdrawingMax(false)
                          }}
                          onBlur={field.handleBlur}
                          value={field.state.value}
                          name={field.name}
                          inputRef={ref}
                        />
                        <button type="button" onClick={handleMax}>
                          MAX
                        </button>
                        {isLoading ? (
                          <div className="text-sm pt-3 text-right flex justify-end h-8">
                            <Skeleton height="h-2" width="w-40" />
                          </div>
                        ) : (
                          <FieldInfo field={field} />
                        )}
                      </>
                    )
                  }}
                </form.Field>
              </div>
            </div>
            <div className="pb-4 mb-4 mt-8 flex flex-col md:flex-row items-center justify-center">
              <ConnectButtonWrapper>
                <form.Subscribe
                  selector={(state) => [state.canSubmit, state.isSubmitting]}
                >
                  {([canSubmit]) => (
                    <Button isDisabled={!canSubmit}>Unstake {symbol}</Button>
                  )}
                </form.Subscribe>
              </ConnectButtonWrapper>
              {isRemoveLiquidityButtonActive && (
                <a
                  href="https://app.uniswap.org/remove/v2/0x412F4b922bB9afdc65c7e9B669F4bEC6e554452a/ETH"
                  target="_blank"
                  rel="noreferrer"
                  className="block text-center min-w-[196px] bg-uniswap text-xs font-bold text-white justify-center rounded-full border border-transparent px-6 py-2 ml-0 md:ml-4 mt-4 md:mt-0"
                >
                  <span className="flex h-6 items-center justify-center">
                    Remove Liquidity
                  </span>
                </a>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
