import { FC } from 'react'
import { Form } from '../components/Form'
import { MainCard } from '@/core/components/MainCard'
import { useCommon } from '@/core/hooks/useCommon'

export const IndexPage: FC = () => {
  const { poolId } = useCommon()
  return (
    <MainCard isCurrentBalanceShown>
      <Form isRemoveLiquidityButtonActive={Number(poolId) === 1} />
    </MainCard>
  )
}
