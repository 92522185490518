import { arbitrumStakingAbi } from '@/abi'
import { useCommon } from '@/core/hooks/useCommon'
import { useCallback, useEffect, useState } from 'react'
import { useWaitForTransactionReceipt, useWriteContract } from 'wagmi'
import { toast } from 'react-toastify'
import { usePoolStats } from '@/core/hooks/usePoolStats'

export const useClaim = () => {
  const [hash, setHash] = useState<string | null>(null)
  const [isTransactionAborted, setTransactionAborted] = useState(false)
  const {
    parseError,
    data,
    stakingContractAddress,
    refetchBalance,
    balance,
    userRewardData,
    isLoading,
    symbol,
    poolId,
  } = useCommon()
  const { writeContract } = useWriteContract()
  const { refetch: refetchStats } = usePoolStats()

  const { isLoading: isTransactionLoading } = useWaitForTransactionReceipt({
    hash: (hash as `0x${string}`) ?? undefined,
    pollingInterval: 1_000,
    query: {
      enabled: !!hash,
    },
  })

  useEffect(() => {
    if (!isTransactionLoading && hash) {
      refetchBalance()
      refetchStats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransactionLoading, hash])

  const onClaim = useCallback(() => {
    writeContract(
      {
        chainId: data?.chain?.id,
        address: stakingContractAddress || '0x0',
        functionName: 'claim',
        abi: arbitrumStakingAbi,
        args: [BigInt(poolId as string)],
      },
      {
        onSuccess: (hash) => {
          setHash(hash)
          toast.success('Claim submitted successfully')
        },
        onError: (error) => {
          setTransactionAborted(true)
          parseError(error).then((parsedError) =>
            toast.error(parsedError || 'An error occurred')
          )
        },
      }
    )
  }, [data, stakingContractAddress, writeContract, parseError, poolId])

  return {
    isLoading,
    isTransactionAborted,
    currentClaimableReward: userRewardData.formatted,
    isTransactionLoading,
    setTransactionAborted,
    balance,
    onClaim,
    symbol,
  }
}
