import { FC, useMemo } from 'react'
import { Dialog } from '@headlessui/react'
import { useConnections } from 'wagmi'
import { classNames } from '../utils'

import MetaMaskIcon from '@/assets/images/MetaMask.svg'

interface DialogProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  isStepTwo?: boolean
}

export const TransactionDialog: FC<DialogProps> = ({
  isOpen,
  setIsOpen,
  isStepTwo,
}) => {
  const connections = useConnections()
  const connector = useMemo(() => {
    return connections?.[0]?.connector
  }, [connections])

  const connectorData = useMemo(() => {
    switch (connector?.name) {
      case 'MetaMask':
        return { name: connector?.name, icon: connector?.icon || MetaMaskIcon }
      default:
        return { name: connector?.name, icon: connector?.icon }
    }
  }, [connector])

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="mx-auto max-w-lg rounded-2xl p-4 bg-white text-cool-gray-800">
          <div className="mx-4">
            <ol className="items-center pt-4 w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse">
              <li
                className={classNames(
                  'flex items-center text-cool-gray-800 space-x-2.5 rtl:space-x-reverse',
                  !isStepTwo ? 'animate-pulse' : ''
                )}
              >
                <span className="flex items-center justify-center w-8 h-8 border border-cool-gray-800 rounded-full shrink-0 ">
                  1
                </span>
                <span>
                  <h3 className="font-medium leading-tight">
                    Confirm transaction
                  </h3>
                </span>
              </li>
              <li
                className={classNames(
                  'flex items-center  space-x-2.5 rtl:space-x-reverse',
                  isStepTwo
                    ? 'text-cool-gray-800 animate-pulse'
                    : 'text-cool-gray-300'
                )}
              >
                <span
                  className={classNames(
                    'flex items-center justify-center w-8 h-8 border rounded-full shrink-0',
                    isStepTwo ? 'border-cool-gray-800' : 'border-cool-gray-300'
                  )}
                >
                  2
                </span>
                <span>
                  <h3 className="font-medium leading-tight">
                    Initiate transfer
                  </h3>
                </span>
              </li>
              <li className="flex items-center text-cool-gray-300 space-x-2.5 rtl:space-x-reverse">
                <span className="flex items-center justify-center w-8 h-8 border border-cool-gray-300 rounded-full shrink-0 ">
                  3
                </span>
                <span>
                  <h3 className="font-medium leading-tight">
                    Waiting for transaction to be received
                  </h3>
                </span>
              </li>
            </ol>
          </div>
          <div className="flex flex-col justify-center text-center mt-8">
            <img
              fetchpriority="low"
              className="flex items-center justify-center w-20 h-20 m-auto"
              src={connectorData.icon}
              alt=""
            />
            <h1 className="pb-6 pt-0 flex items-center justify-center">
              <div className="py-2.5 px-5 me-2 text-lg font-medium focus:z-10 focus:ring-2 inline-flex items-center">
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-3 text-cool-gray-200 animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    className="fill-white"
                  />
                </svg>
                {connectorData.name} confirmation
                <span className="animate-pulse">...</span>
              </div>
            </h1>
            <p className="text-xs text-cool-gray-900 mb-4 mx-20">
              Do not refresh or close the page while waiting for the operation
              to be completed.
            </p>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
