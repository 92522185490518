import { FC, useState } from 'react'
import { Socials } from './Socials'

import logo from '@/assets/images/logo.svg'
import { useNavigate } from 'react-router-dom'
import { LaunchWebAppLink } from './LaunchWebAppLink'

export const TopNavBar: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navigate = useNavigate()

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <div className="h-16 w-full">
      <div className="fixed w-full h-32 -top-16 bg-cool-gray-800 z-20"></div>
      <nav
        className={`z-20 shadow-2xl flex fixed items-center justify-between flex-wrap bg-cool-gray-800 w-full ${isMenuOpen ? '!h-16 position-sticky' : ''}`}
      >
        <div className="flex items-center flex-shrink-0 text-white h-16 py-4 md:w-40">
          <div onClick={() => navigate('/')} className="cursor-pointer">
            <img src={logo} alt="RepubliK logo" className="h-8 w-8 mx-4" />
            <span className="font-semibold text-xl tracking-tight"></span>
          </div>
        </div>
        <div className="block md:hidden">
          <button
            onClick={toggleMenu}
            className="flex items-center px-3 py-2 mr-4 border rounded text-white border-white"
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={`w-full ${isMenuOpen ? 'block' : 'hidden'} md:flex md:items-center md:w-auto md:flex-grow-0 overflow-x-auto bg-cool-gray-800 pb-6 md:py-3 lg:min-w-[512px] md:h-16`}
        >
          <div className="text-sm md:flex-grow grid px-4 md:px-0 md:flex grid-cols-2 md:grid-rows-1 md:grid-cols-none">
            <a
              href="https://republik.gg/"
              target="_blank"
              rel="noreferrer"
              className="block mt-4 md:inline-block md:mt-0 text-white hover:text-white md:mr-3 text-center md:text-left"
            >
              RepubliK Website
            </a>
            <a
              href="#faq"
              className="block mt-4 md:inline-block md:mt-0 text-white hover:text-white md:mr-3 text-center md:text-left"
            >
              FAQ
            </a>
            <a
              href="https://republik.gg/metrics/"
              className="block mt-4 md:inline-block md:mt-0 text-white hover:text-white md:mr-3 text-center md:text-left"
              target="_blank"
              rel="noreferrer"
            >
              Metrics
            </a>
          </div>
          <div className="py-4 md:py-0 md:pl-4 flex justify-center">
            <LaunchWebAppLink />
          </div>
          <div className="md:hidden">
            <Socials />
          </div>
        </div>
        <div className={`hidden md:flex md:w-40 pr-3 justify-end`}>
          <Socials />
        </div>
      </nav>
    </div>
  )
}
