import { useConnectModal } from '@rainbow-me/rainbowkit'
import { FC } from 'react'
import { Button } from './Button'
import { useAccount } from 'wagmi'
import useConnectionStatus from '../hooks/useConnectionStatus'
import { WarningDialog } from './WarningDialog'
import { useWarningDialog } from '../hooks/useWarningDialog'

interface ConnectButtonWrapperProps {
  children: React.ReactNode
}

export const ConnectButtonWrapper: FC<ConnectButtonWrapperProps> = ({
  children,
}) => {
  const { openConnectModal } = useConnectModal()
  const { isConnected } = useAccount()
  const isOnline = useConnectionStatus()
  const { isOpen, open, close, isActive } = useWarningDialog()

  return (
    <>
      <WarningDialog
        isOpen={isOpen}
        setIsOpen={open}
        onAck={() => {
          close()
          openConnectModal?.()
        }}
      />
      {isConnected ? (
        children
      ) : (
        <Button
          type="button"
          onClick={() => (isActive ? openConnectModal?.() : open(true))}
          isDisabled={!isOnline}
        >
          Connect Wallet
        </Button>
      )}
    </>
  )
}
