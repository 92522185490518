import {
  arbitrumAbi,
  arbitrumStakingAbi,
  arbitrumLiquidityPoolAbi,
} from '@/abi'
import { appConfig } from '@/config'
import { ErrorDecoder } from 'ethers-decode-error'
import {
  useAccount,
  useBalance,
  useReadContract,
  useReadContracts,
  useWalletClient,
} from 'wagmi'
import { formatCurrency } from '../utils'
import { useCallback, useMemo } from 'react'
import { formatEther } from 'viem'
import { arbitrum } from 'wagmi/chains'
import { fixString } from '../utils/formatCurrency'
import useConnectionStatus from './useConnectionStatus'
import { useParams } from 'react-router-dom'
import { normalizeSymbol } from '../utils/normalizeSymbol'

const errorDecoder = ErrorDecoder.create()

export const useCommon = () => {
  const { data } = useWalletClient()
  const { isConnected } = useAccount()
  const isOnline = useConnectionStatus()
  const { poolId } = useParams()

  const isDev = appConfig.stage === 'development' || import.meta.env.DEV

  const isMainNet = useMemo(
    () => (isConnected ? data?.chain?.id === arbitrum.id : !isDev),
    [data, isConnected, isDev]
  )

  const stakingContractAddress = appConfig.arbitrumStakingContractAddress

  const stakingContractUrl = useMemo(
    () =>
      isMainNet
        ? `https://arbiscan.io/address/` +
          appConfig.arbitrumStakingContractAddress
        : `https://sepolia.arbiscan.io/address/` +
          appConfig.sepoliaArbitrumStakingContractAddress,
    [isMainNet]
  )

  const parseError = async (error: unknown) => {
    const { reason } = await errorDecoder.decode(error)
    console.log(reason)

    if (!reason) return

    const regex = /ERC20: (.*?)\n/
    const match = regex.exec(reason)

    return match ? match[1] : 'Transaction failed'
  }

  const {
    data: usersPositionData,
    refetch: refetchUsersPositionData,
    isLoading,
  } = useReadContracts({
    contracts: [
      {
        abi: arbitrumStakingAbi,
        address: stakingContractAddress || '0x0',
        args: [
          BigInt(poolId as string),
          data?.account.address as `0x${string}`,
        ],
        functionName: 'usersPosition',
      },
      {
        abi: arbitrumStakingAbi,
        address: stakingContractAddress || '0x0',
        args: [BigInt(poolId as string)],
        functionName: 'pools',
      },
    ],
    query: {
      enabled: isOnline && isConnected && !!poolId,
    },
  })

  const contractAddress = useMemo<'0x${string}'>(
    () =>
      (usersPositionData?.[1].result?.[1] as unknown as '0x${string}') || '0x0',
    [usersPositionData]
  )

  const {
    data: balanceData,
    refetch: refetchUserBalanceData,
    isLoading: isLoadingBalance,
  } = useBalance({
    chainId: data?.chain?.id,
    address: data?.account.address,
    token: contractAddress || '0x0',
    query: {
      enabled: isOnline && isConnected,
    },
  })

  const contractAbi =
    Number(poolId) === 0 ? arbitrumAbi : arbitrumLiquidityPoolAbi

  const { data: contractsData } = useReadContracts({
    contracts: [
      {
        chainId: data?.chain?.id,
        address: contractAddress || '0x0',
        abi: contractAbi,
        functionName: 'symbol',
      },
      {
        chainId: data?.chain?.id,
        address: contractAddress || '0x0',
        abi: contractAbi,
        functionName: 'name',
      },
    ],
    query: {
      enabled: isOnline && isConnected,
    },
  })

  const { data: currentUserRewardData } = useReadContract({
    chainId: data?.chain?.id,
    address: stakingContractAddress || '0x0',
    abi: arbitrumStakingAbi,
    functionName: 'currentUserReward',
    args: [BigInt(poolId as string), data?.account.address as `0x${string}`],
    query: {
      refetchInterval: usersPositionData?.[0] ? 1000 * 5 : 0,
      enabled: isOnline && isConnected && !!poolId,
    },
  })

  const stakedAmountData = useMemo(() => {
    const amount = formatEther(usersPositionData?.[0].result?.[0] || 0n)
    return {
      formatted: formatCurrency(amount),
      value: fixString(amount),
      bigint: usersPositionData?.[0].result?.[0] || 0n,
    }
  }, [usersPositionData])

  const userRewardData = useMemo(() => {
    const amount = formatEther(currentUserRewardData || 0n)
    return { formatted: formatCurrency(amount, 5), value: amount }
  }, [currentUserRewardData])

  const balance = useMemo(() => {
    return {
      formatted: formatCurrency(balanceData?.formatted || '0'),
      value: fixString(balanceData?.formatted || '0'),
      bigint: balanceData?.value || 0n,
    }
  }, [balanceData])

  const refetchBalance = useCallback(() => {
    refetchUserBalanceData()
    refetchUsersPositionData()
  }, [refetchUserBalanceData, refetchUsersPositionData])

  return {
    poolId,
    symbol: normalizeSymbol(contractsData?.[0]?.result as string),
    name: contractsData?.[1]?.result,
    usersPositionData,
    refetchBalance,
    isLoading,
    balance,
    isLoadingBalance,
    data,
    parseError,
    contractAddress,
    stakingContractAddress,
    userRewardData,
    stakedAmountData,
    stakingContractUrl,
    isMainNet,
  }
}
