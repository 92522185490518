import { useReadContracts } from 'wagmi'
import { arbitrum } from 'wagmi/chains'
import { arbitrumLiquidityPoolAbi, arbitrumStakingAbi } from '@/abi'
import { useCallback, useMemo } from 'react'
import { formatEther } from 'viem'
import { calculateApy, formatCurrency } from '@/core/utils'
import { useCommon } from './useCommon'
import useConnectionStatus from './useConnectionStatus'

export const usePoolStats = ({
  enabled = true,
}: { enabled?: boolean } = {}) => {
  const { data, stakingContractAddress, stakingContractUrl, symbol, poolId } =
    useCommon()
  const isOnline = useConnectionStatus()

  const chainId = data ? data.chain?.id : arbitrum.id

  const {
    data: contractsData,
    isLoading,
    refetch,
  } = useReadContracts({
    contracts: [
      {
        chainId,
        address: stakingContractAddress || '0x0',
        abi: arbitrumStakingAbi,
        functionName: 'rewardRate',
      },
      {
        chainId,
        address: stakingContractAddress || '0x0',
        abi: arbitrumStakingAbi,
        functionName: 'totalWeight',
      },
      {
        chainId,
        address: stakingContractAddress || '0x0',
        abi: arbitrumStakingAbi,
        functionName: 'pools',
        args: [BigInt(poolId as string)],
      },
    ],
    query: {
      enabled: isOnline ? !!stakingContractAddress && enabled : false,
    },
  })

  const arbitrumLPContractAddress = (
    contractsData?.[2]?.result as unknown as bigint[]
  )?.[1]

  const { data: lPContractsData } = useReadContracts({
    contracts: [
      {
        chainId: arbitrum.id,
        address:
          (arbitrumLPContractAddress as unknown as `0x${string}`) || '0x0',
        abi: arbitrumLiquidityPoolAbi,
        functionName: 'getReserves',
      },
      {
        chainId: arbitrum.id,
        address:
          (arbitrumLPContractAddress as unknown as `0x${string}`) || '0x0',
        abi: arbitrumLiquidityPoolAbi,
        functionName: 'totalSupply',
      },
    ],
    query: {
      enabled: isOnline
        ? !!(
            stakingContractAddress &&
            contractsData?.[2]?.result &&
            Number(poolId) === 1
          )
        : false,
      refetchInterval: 1000 * 10,
    },
  })

  const calculateLPTotalValue = useCallback(() => {
    let lpTotalValue: bigint = 0n

    const poolDeposited =
      (contractsData?.[2]?.result as unknown as bigint[])?.[3] || 0n

    const reserver =
      (lPContractsData?.[0]?.result as unknown as bigint[])?.[0] || 0n

    const totalSupply =
      (lPContractsData?.[1]?.result as unknown as bigint) || 0n

    if (totalSupply !== 0n) {
      lpTotalValue =
        (BigInt(reserver) * BigInt(poolDeposited) * 2n) / BigInt(totalSupply)
    }

    return lpTotalValue
  }, [contractsData, lPContractsData])

  const apy = useMemo(() => {
    const rewardRate = contractsData?.[0]?.result || 0n
    const poolWeight = contractsData?.[2]?.result?.[2] || 0n
    const totalWeight = contractsData?.[1]?.result || 0n
    let poolDeposited = contractsData?.[2]?.result?.[3] || 0n

    if (Number(poolId) === 1) {
      poolDeposited = calculateLPTotalValue()
    }

    return (
      calculateApy({
        rewardRate,
        poolWeight,
        totalWeight,
        poolDeposited,
      }).toFixed(0) + '%'
    )
  }, [contractsData, calculateLPTotalValue, poolId])

  const tvl = useMemo(() => {
    const amount =
      Number(poolId) === 1
        ? String(calculateLPTotalValue() / BigInt(10 ** 18))
        : formatEther(contractsData?.[2]?.result?.[3] || 0n)

    return formatCurrency(amount)
  }, [contractsData, poolId, calculateLPTotalValue])

  return {
    symbol,
    refetch,
    isLoading,
    apy,
    tvl,
    stakingContractUrl,
    arbitrumLPContractAddress,
  }
}
