import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { queryClient } from '@/config'

export const useWarningDialog = () => {
  const data = queryClient.getQueryState(['warning-dialog'])

  const { mutate } = useMutation({
    mutationFn: (value: string) => {
      queryClient.setQueryData(['warning-dialog'], value)
      return Promise.resolve()
    },
    onSuccess: () => {
      setIsOpen(false)
    },
  })

  const isActive = !!data
  const [isOpen, setIsOpen] = useState(false)

  const open = (value: boolean) => setIsOpen(value)

  const close = () => {
    mutate('ack')
  }

  return { isOpen, open, close, isActive }
}
